import { template as template_ab4956dfe77d478cb28fc0b90f607707 } from "@ember/template-compiler";
const WelcomeHeader = template_ab4956dfe77d478cb28fc0b90f607707(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
