import { template as template_141b1b611e69424c8beb04db660bafb1 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ActivityCell = template_141b1b611e69424c8beb04db660bafb1(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="activity"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="activity"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ActivityCell;
