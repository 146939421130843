import { template as template_acf5839141e74dd5b85c21afa3b095ba } from "@ember/template-compiler";
const FKLabel = template_acf5839141e74dd5b85c21afa3b095ba(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
