import { template as template_59e197f3a10c4c6c80b665f06e209ca3 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ViewsCell = template_59e197f3a10c4c6c80b665f06e209ca3(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="views"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="views"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ViewsCell;
